<template>
  <div class="register">
    <top-bar back backroute="auth" is-auth />
    <div class="container">
      <div class="img">
        <n-icon icon="logo" />
      </div>

      <div class="title">
        <t name="3.1" />
      </div>

      <div class="roles">
        <div class="role" @click="selectRole(1)">
          <div class="info">
            <n-icon icon="info" />
          </div>
          <div class="icon">
            <n-icon icon="seller" />
          </div>
          <div class="title">
            <t name="3.2" />
          </div>
        </div>
        <div class="role buyer" @click="selectRole(0)">
          <div class="info">
            <n-icon icon="info" />
          </div>
          <div class="icon">
            <n-icon icon="buyer" />
          </div>
          <div class="title">
            <t name="3.3" />
          </div>
        </div>
      </div>

      <div class="subtitle">
        <t name="3.4" />
      </div>
    </div>
    <div class="bottom">
      <div v-show="infoText.length !== 0" class="info-place">
        <div class="text">
          {{ infoText }}
        </div>
        <div class="button-place">
          <n-button wide color="yellow" @click="next">
            <t name="3.7" />
          </n-button>
        </div>
      </div>
      <img :src="imgBottom" alt="">
    </div>
  </div>
</template>

<script>
import img from 'assets/user.png'
import logo from 'assets/logo-reg.png'
import imgBottom from 'assets/reg-bottom.png'

export default {
  name: 'PageRegister',
  data() {
    return {
      img, logo,
      imgBottom,
      role: null,
      infos: {
        0: $app.secure.clean($n.t('3.6')),
        1: $app.secure.clean($n.t('3.5')),
      },
      infoText: '',
    }
  },
  methods: {
    selectRole(role) {
      this.infoText = this.infos[role]
      this.role = role
    },
    next() {
      this.$router.push({ name: 'register2', query: { role: this.role, }, })
    },
  },
}
</script>

<style scoped lang="scss">
.register {
  height: 90vh;
  position: relative;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .button-place {
    margin: 15px;
    width: 280px;
  }

  .bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(env(safe-area-inset-bottom) - 65px);

    .info-place {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .text {
        padding: 10px;
        text-align: center;
        background-color: #F3F3F3;
        border: 1px solid #FAAD14;
        border-radius: 6px;
        max-width: 300px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 100px;
    margin: 8px auto 8px;
    position: relative;
    z-index: 13;

    svg{
      width: 100%;
    }
  }

  .roles {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    .role:last-child {
      margin-right: 0;
    }

    .role {
      margin-right: 15px;
      position: relative;
      width: 150px;
      height: 220px;
      background-color: #FAAD14;
      border-radius: 15px;
      padding: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .info {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      &.buyer {
        background-color: #66AB3D;
      }

      .icon {
        margin-top: 25px;
      }

      .title {
        margin-top: auto;
        font-size: 22px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }

  .title {
    margin-top: 15px;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 4px;
  }

  .subtitle {
    margin-top: 30px;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }

  .buttons {
    .n-button:first-child {
      margin-bottom: 12px;
    }

    .n-button {
      position: relative;

      a {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }
  }
}
</style>
